@import "../../node_modules/bootstrap/scss/variables";

/* Адрес цветовой схемы: https://colorscheme.ru/#38b1TtexHw0w0 */
$color-primary-0: #009966;	/* Основной Цвет */
$color-primary-1: #339966;
$color-primary-2: #006666;
$color-primary-3: #33cc99;
$color-primary-4: #66cccc;
$color-primary-5: #9bbfa9;
$color-primary-list: $color-primary-0 $color-primary-1 $color-primary-2 $color-primary-3 $color-primary-4 $color-primary-5;

$color-secondary-a-0: #ff9900;	/* Вторичный Цвет A */
$color-secondary-a-1: #cc9933;
$color-secondary-a-2: #996600;
$color-secondary-a-3: #ffcc66;
$color-secondary-a-4: #ffcc66;
// $color-secondary-a-list: $color-secondary-a-0 $color-secondary-a-1 $color-secondary-a-2 $color-secondary-a-3 $color-secondary-a-4;

$color-secondary-b-0: #ff0033;	/* Вторичный Цвет B */
$color-secondary-b-1: #993366;
$color-secondary-b-2: #990033;
$color-secondary-b-3: #ff6666;
$color-secondary-b-4: #ff6699;
$color-secondary-b-3-light: #ffefef;
$color-secondary-b-5: #b33959;

/* Цветовые части сайта */
$color-header-bg: $color-primary-1;
$color-header-text: $white;
$color-header-link: $white;
$color-header-link-hover: $color-primary-4;
$color-header-link-hover-bg: $color-primary-2;

$color-main-bg: $color-secondary-b-3-light;
$color-text-main: $black;
$color-text-head: $color-secondary-b-2;
$color-link: $color-secondary-a-2;
$color-link-hover-text: $color-primary-4;
$color-link-hover-bg: $color-primary-1;

$color-footer-bg: $color-primary-5;
$color-footer-text: $color-primary-2;
$color-footer-text-extra: $gray-800;
$color-footer-link: $color-secondary-a-2;
$color-footer-link-hover: $color-primary-4;
$color-footer-link-hover-bg: $color-primary-2;

.bg-color-main {
  background-color: $color-primary-1;
}
.color-main {
  color: $color-text-main;
}
@for $i from 1 through length($color-primary-list) {
  $c: nth($color-primary-list, $i);
  .color-primary-#{$i - 1} {
    color: $c;
  }
  .bg-primary-#{$i - 1} {
    background-color: $c;
  }
  .hover-bg-primary-#{$i - 1}:hover {
    background-color: $c;
  }
}
@for $i from 1 through 9 {
  .hover-opacity-#{$i*10} {
    opacity: $i * 10%;
  }
}
.color-link {
  color: $color-link;
}
.color-gray {
  color: $gray-400;
}
.color-gray-800 {
  color: $gray-800;
}
.line-color-main {
  border: 1px solid $color-text-main;
}
.line-top-color-main {
  border-top: 1px solid $color-text-main;
}
.line-bottom-color-main {
  border-bottom: 1px solid $color-text-main;
}
.line-left-color-main {
  border-left: 1px solid $color-text-main;
}
.line-right-color-main {
  border-right: 1px solid $color-text-main;
}
.main-h1 {
  color: $color-text-head;
}
.main-h2 {
  color: $color-text-head;
}
.main-h3 {
  color: $color-text-main;
}
.main-h4 {
  color: $color-text-main;
}
/*
.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: $main-color-green;
}*/

.color-warning {
  color: $color-secondary-a-1;
}
.error {
  color: $danger;
}
.btn-success {
  color: $white;
  background-color: $color-secondary-b-2;
  border-color: $color-secondary-b-2;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: $color-secondary-b-1;
  border-color: $color-secondary-b-1;
}
.btn-success:hover {
  background-color: $color-secondary-b-5;
  border-color: $color-secondary-b-5;
}
.btn-outline-success {
  background-color: $white;
  border-color: $color-secondary-b-2;
  color: $color-secondary-b-2;
}
.btn-outline-success:hover {
  background-color: $color-secondary-b-5;
  border-color: $color-secondary-b-5;
  color:$white;
}

td.color-calendar-workday,
th.color-calendar-workday {
  color: $gray-700;
  border-top: 1px solid $gray-700;
  border-right:4px solid  $gray-700;
  padding-top:0;
  padding-right:0.25rem;
  padding-bottom:2rem;
}
td.color-calendar-weekend,
th.color-calendar-weekend {
  color: $color-secondary-a-0;
  border-top: 1px solid $color-secondary-a-0;
  border-right:4px solid  $color-secondary-a-0;
  padding-top:0;
  padding-right:0.25rem;
  padding-bottom:2rem;
}
.img-up {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left:0;
  top: 0.25rem;
}
.img-down {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  bottom: -0.25rem;
}
@media (max-width: 430px) {
  .img-down {
    left: 0;
  }
}
@media (min-width: 431px) and (max-width: 767.98px) {
  .img-down {
    right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .img-up {
    width: 2rem;
    height: 2rem;
  }
  .img-down {
    width: 2rem;
    height: 2rem;
    left: 2rem;
  }
}
dt, dd {  display: inline-block ;}
dt img {
  width: 2rem;
  height: 2rem;
}
.img-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.table thead th {
  border: none;
}
#header {
  color: $color-header-text;
  background-color: $color-header-bg;

  .dropdown-item.active,
  .dropdown-item:active {
    color: $white;
    background-color: $color-primary-2;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: $gray-300;
  }

  a.nav-link,
  a.navbar-toggler {
    color: $color-header-link;
  }
  a.nav-link:hover,
  a.nav-link.active,
  a.navbar-toggler:hover,
  .dropdown:hover a.nav-link.dropdown-toggle {
    color: $color-header-link-hover;
    background-color: $color-header-link-hover-bg;
  }
}

/* стили футера */
#footer {
  color: $color-footer-text;
  background-color: $color-footer-bg;
  border-top: 1px solid $color-main-bg;
  .card {
    border: none;
    background-color: transparent;
  }
  a.btn {
    color: $color-footer-link;
  }
  a.btn:not(.collapsed) {
    color: $color-footer-link-hover;
    background-color: $color-footer-link-hover-bg;
  }
  a {
    color: $color-footer-link;
  }
  a:hover {
    color: $color-footer-link-hover;
    background-color: $color-footer-link-hover-bg;
  }
  .socials a:hover {
    background-color: transparent;
  }
  .text-extra {
    color: $color-footer-text-extra;
  }
}
