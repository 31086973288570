@charset "UTF-8";
/* Адрес цветовой схемы: https://colorscheme.ru/#38b1TtexHw0w0 */
/* Основной Цвет */
/* Вторичный Цвет A */
/* Вторичный Цвет B */
/* Цветовые части сайта */
.bg-color-main {
  background-color: #339966;
}

.color-main {
  color: #000;
}

.color-primary-0 {
  color: #009966;
}

.bg-primary-0 {
  background-color: #009966;
}

.hover-bg-primary-0:hover {
  background-color: #009966;
}

.color-primary-1 {
  color: #339966;
}

.bg-primary-1 {
  background-color: #339966;
}

.hover-bg-primary-1:hover {
  background-color: #339966;
}

.color-primary-2 {
  color: #006666;
}

.bg-primary-2 {
  background-color: #006666;
}

.hover-bg-primary-2:hover {
  background-color: #006666;
}

.color-primary-3 {
  color: #33cc99;
}

.bg-primary-3 {
  background-color: #33cc99;
}

.hover-bg-primary-3:hover {
  background-color: #33cc99;
}

.color-primary-4 {
  color: #66cccc;
}

.bg-primary-4 {
  background-color: #66cccc;
}

.hover-bg-primary-4:hover {
  background-color: #66cccc;
}

.color-primary-5 {
  color: #9bbfa9;
}

.bg-primary-5 {
  background-color: #9bbfa9;
}

.hover-bg-primary-5:hover {
  background-color: #9bbfa9;
}

.hover-opacity-10 {
  opacity: 10%;
}

.hover-opacity-20 {
  opacity: 20%;
}

.hover-opacity-30 {
  opacity: 30%;
}

.hover-opacity-40 {
  opacity: 40%;
}

.hover-opacity-50 {
  opacity: 50%;
}

.hover-opacity-60 {
  opacity: 60%;
}

.hover-opacity-70 {
  opacity: 70%;
}

.hover-opacity-80 {
  opacity: 80%;
}

.hover-opacity-90 {
  opacity: 90%;
}

.color-link {
  color: #996600;
}

.color-gray {
  color: #ced4da;
}

.color-gray-800 {
  color: #343a40;
}

.line-color-main {
  border: 1px solid #000;
}

.line-top-color-main {
  border-top: 1px solid #000;
}

.line-bottom-color-main {
  border-bottom: 1px solid #000;
}

.line-left-color-main {
  border-left: 1px solid #000;
}

.line-right-color-main {
  border-right: 1px solid #000;
}

.main-h1 {
  color: #990033;
}

.main-h2 {
  color: #990033;
}

.main-h3 {
  color: #000;
}

.main-h4 {
  color: #000;
}

/*
.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: $main-color-green;
}*/
.color-warning {
  color: #cc9933;
}

.error {
  color: #dc3545;
}

.btn-success {
  color: #fff;
  background-color: #990033;
  border-color: #990033;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: #993366;
  border-color: #993366;
}

.btn-success:hover {
  background-color: #b33959;
  border-color: #b33959;
}

.btn-outline-success {
  background-color: #fff;
  border-color: #990033;
  color: #990033;
}

.btn-outline-success:hover {
  background-color: #b33959;
  border-color: #b33959;
  color: #fff;
}

td.color-calendar-workday,
th.color-calendar-workday {
  color: #495057;
  border-top: 1px solid #495057;
  border-right: 4px solid #495057;
  padding-top: 0;
  padding-right: 0.25rem;
  padding-bottom: 2rem;
}

td.color-calendar-weekend,
th.color-calendar-weekend {
  color: #ff9900;
  border-top: 1px solid #ff9900;
  border-right: 4px solid #ff9900;
  padding-top: 0;
  padding-right: 0.25rem;
  padding-bottom: 2rem;
}

.img-up {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 0;
  top: 0.25rem;
}

.img-down {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  bottom: -0.25rem;
}

@media (max-width: 430px) {
  .img-down {
    left: 0;
  }
}

@media (min-width: 431px) and (max-width: 767.98px) {
  .img-down {
    right: 0.5rem;
  }
}

@media (min-width: 768px) {
  .img-up {
    width: 2rem;
    height: 2rem;
  }
  .img-down {
    width: 2rem;
    height: 2rem;
    left: 2rem;
  }
}

dt, dd {
  display: inline-block;
}

dt img {
  width: 2rem;
  height: 2rem;
}

.img-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.table thead th {
  border: none;
}

#header {
  color: #fff;
  background-color: #339966;
}

#header .dropdown-item.active,
#header .dropdown-item:active {
  color: #fff;
  background-color: #006666;
}

#header .dropdown-item:focus,
#header .dropdown-item:hover {
  background-color: #dee2e6;
}

#header a.nav-link,
#header a.navbar-toggler {
  color: #fff;
}

#header a.nav-link:hover,
#header a.nav-link.active,
#header a.navbar-toggler:hover,
#header .dropdown:hover a.nav-link.dropdown-toggle {
  color: #66cccc;
  background-color: #006666;
}

/* стили футера */
#footer {
  color: #006666;
  background-color: #9bbfa9;
  border-top: 1px solid #ffefef;
}

#footer .card {
  border: none;
  background-color: transparent;
}

#footer a.btn {
  color: #996600;
}

#footer a.btn:not(.collapsed) {
  color: #66cccc;
  background-color: #006666;
}

#footer a {
  color: #996600;
}

#footer a:hover {
  color: #66cccc;
  background-color: #006666;
}

#footer .socials a:hover {
  background-color: transparent;
}

#footer .text-extra {
  color: #343a40;
}

@font-face {
  font-family: 'Comfortaa';
  font-display: swap;
  src: url("/media/fonts/Comfortaa-Bold.eot");
  src: local("Comfortaa Bold"), local("Comfortaa-Bold"), url("/media/fonts/Comfortaa-Bold.eot?#iefix") format("embedded-opentype"), url("/media/fonts/Comfortaa-Bold.woff2") format("woff2"), url("/media/fonts/Comfortaa-Bold.woff") format("woff"), url("/media/fonts/Comfortaa-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  font-display: swap;
  src: url("/media/fonts/Comfortaa-Regular.eot");
  src: local("Comfortaa Regular"), local("Comfortaa-Regular"), url("/media/fonts/Comfortaa-Regular.eot?#iefix") format("embedded-opentype"), url("/media/fonts/Comfortaa-Regular.woff2") format("woff2"), url("/media/fonts/Comfortaa-Regular.woff") format("woff"), url("/media/fonts/Comfortaa-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.font-normal {
  font-size: 1.2rem;
}

.font-small {
  font-size: 1rem;
}

.font-little {
  font-size: 0.8rem;
}

.font-0 {
  font-size: 0%;
}

.font-5 {
  font-size: 5%;
}

.font-10 {
  font-size: 10%;
}

.font-15 {
  font-size: 15%;
}

.font-20 {
  font-size: 20%;
}

.font-25 {
  font-size: 25%;
}

.font-30 {
  font-size: 30%;
}

.font-35 {
  font-size: 35%;
}

.font-40 {
  font-size: 40%;
}

.font-45 {
  font-size: 45%;
}

.font-50 {
  font-size: 50%;
}

.font-55 {
  font-size: 55%;
}

.font-60 {
  font-size: 60%;
}

.font-65 {
  font-size: 65%;
}

.font-70 {
  font-size: 70%;
}

.font-75 {
  font-size: 75%;
}

.font-80 {
  font-size: 80%;
}

.font-85 {
  font-size: 85%;
}

.font-90 {
  font-size: 90%;
}

.font-95 {
  font-size: 95%;
}

.font-100 {
  font-size: 100%;
}

.font-105 {
  font-size: 105%;
}

.font-110 {
  font-size: 110%;
}

.font-115 {
  font-size: 115%;
}

.font-120 {
  font-size: 120%;
}

.font-125 {
  font-size: 125%;
}

.font-130 {
  font-size: 130%;
}

.font-135 {
  font-size: 135%;
}

.font-140 {
  font-size: 140%;
}

.font-145 {
  font-size: 145%;
}

.font-150 {
  font-size: 150%;
}

.font-155 {
  font-size: 155%;
}

.font-160 {
  font-size: 160%;
}

.font-165 {
  font-size: 165%;
}

.font-170 {
  font-size: 170%;
}

.font-175 {
  font-size: 175%;
}

.font-180 {
  font-size: 180%;
}

.font-185 {
  font-size: 185%;
}

.font-190 {
  font-size: 190%;
}

.font-195 {
  font-size: 195%;
}

.font-200 {
  font-size: 200%;
}

/* правила для поворота стрелок в аккордеонах */
.collapse-icon::before {
  transition: transform ease 200ms;
  display: inline-block;
}

.collapsed .collapse-icon::before,
.collapsed.collapse-icon::before {
  transform: rotate(180deg);
}

@media (max-width: -0.02px) {
  .d-xs-none- {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .d-sm-none- {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .d-md-none- {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .d-lg-none- {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-none- {
    display: none;
  }
}

.d-frcc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.d-frcb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.d-frcs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.d-frce {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.d-frsc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.d-frsb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.d-frss {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-frse {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.d-frec {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.d-freb {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.d-fres {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.d-free {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-frbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.d-frbb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.d-frbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.d-frbe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.d-frac {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.d-frab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
}

.d-fras {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.d-frae {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}

.d-fccc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.d-fccb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.d-fccs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.d-fcce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.d-fcsc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.d-fcsb {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
}

.d-fcss {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-fcse {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.d-fcec {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.d-fceb {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
}

.d-fces {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.d-fcee {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-fcbc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.d-fcbb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}

.d-fcbs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.d-fcbe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.d-fcac {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.d-fcab {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
}

.d-fcas {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.d-fcae {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.d-flcc {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.d-flcb {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: baseline;
}

.d-flcs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
}

.d-flce {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-end;
}

.d-flsc {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}

.d-flsb {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: baseline;
}

.d-flss {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-flse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
}

.d-flec {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.d-fleb {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
}

.d-fles {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
}

.d-flee {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-flbc {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.d-flbb {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
}

.d-flbs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.d-flbe {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}

.d-flac {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
}

.d-flab {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: baseline;
}

.d-flas {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: flex-start;
}

.d-flae {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: flex-end;
}

.d-fhcc {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.d-fhcb {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: baseline;
}

.d-fhcs {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
}

.d-fhce {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-end;
}

.d-fhsc {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
}

.d-fhsb {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: baseline;
}

.d-fhss {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-fhse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-end;
}

.d-fhec {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
}

.d-fheb {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: baseline;
}

.d-fhes {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-start;
}

.d-fhee {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
}

.d-fhbc {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}

.d-fhbb {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: baseline;
}

.d-fhbs {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.d-fhbe {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-end;
}

.d-fhac {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
}

.d-fhab {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: baseline;
}

.d-fhas {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: flex-start;
}

.d-fhae {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: flex-end;
}

@media (min-width: 0px) {
  .d-xs-frcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frcc- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-frcb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frcb- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-frcs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frcs- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-frce {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frce- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-frsc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frsc- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-frsb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frsb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-frss {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frss- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-frse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frse- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-frec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frec- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-freb {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-freb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fres {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fres- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-free {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-free- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-frbc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frbc- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-frbb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frbb- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-frbs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frbs- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-frbe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frbe- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-frac {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frac- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-frab {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frab- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fras {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fras- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-frae {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-frae- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fccc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fccc- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fccb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fccb- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fccs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fccs- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fcce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcce- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fcsc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcsc- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fcsb {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcsb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fcss {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcss- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fcse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcse- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fcec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcec- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fceb {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fceb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fces {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fces- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fcee {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcee- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fcbc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcbc- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fcbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcbb- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fcbs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcbs- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fcbe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcbe- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fcac {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcac- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fcab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcab- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fcas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcas- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fcae {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fcae- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-flcc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flcc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-flcb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flcb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-flcs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flcs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-flce {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flce- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-flsc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flsc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-flsb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flsb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-flss {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flss- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-flse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flse- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-flec {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flec- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fleb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fleb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fles- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-flee {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flee- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-flbc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flbc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-flbb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flbb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-flbs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flbs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-flbe {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flbe- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-flac {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flac- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-flab {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flab- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-flas {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flas- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-flae {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-flae- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fhcc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhcc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fhcb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhcb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fhcs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhcs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fhce {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhce- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fhsc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhsc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fhsb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhsb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fhss {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhss- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fhse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhse- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fhec {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhec- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fheb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fheb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fhes {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhes- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fhee {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhee- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fhbc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhbc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fhbb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhbb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fhbs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhbs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fhbe {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhbe- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 0px) {
  .d-xs-fhac {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhac- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 0px) {
  .d-xs-fhab {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhab- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 0px) {
  .d-xs-fhas {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhas- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 0px) {
  .d-xs-fhae {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: -0.02px) {
  .d-xs-fhae- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-frcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frcc- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-frcb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frcb- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-frcs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frcs- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-frce {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frce- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-frsc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frsc- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-frsb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frsb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-frss {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frss- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-frse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frse- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-frec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frec- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-freb {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-freb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fres {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fres- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-free {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-free- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-frbc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frbc- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-frbb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frbb- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-frbs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frbs- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-frbe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frbe- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-frac {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frac- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-frab {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frab- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fras {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fras- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-frae {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-frae- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fccc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fccc- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fccb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fccb- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fccs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fccs- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fcce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcce- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fcsc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcsc- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fcsb {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcsb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fcss {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcss- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fcse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcse- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fcec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcec- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fceb {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fceb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fces {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fces- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fcee {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcee- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fcbc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcbc- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fcbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcbb- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fcbs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcbs- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fcbe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcbe- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fcac {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcac- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fcab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcab- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fcas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcas- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fcae {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fcae- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-flcc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flcc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-flcb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flcb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-flcs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flcs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-flce {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flce- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-flsc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flsc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-flsb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flsb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-flss {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flss- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-flse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flse- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-flec {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flec- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fleb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fleb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fles- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-flee {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flee- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-flbc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flbc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-flbb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flbb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-flbs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flbs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-flbe {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flbe- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-flac {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flac- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-flab {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flab- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-flas {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flas- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-flae {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-flae- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fhcc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhcc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fhcb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhcb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fhcs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhcs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fhce {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhce- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fhsc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhsc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fhsb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhsb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fhss {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhss- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fhse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhse- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fhec {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhec- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fheb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fheb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fhes {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhes- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fhee {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhee- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fhbc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhbc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fhbb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhbb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fhbs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhbs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fhbe {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhbe- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .d-sm-fhac {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhac- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .d-sm-fhab {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhab- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 576px) {
  .d-sm-fhas {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhas- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 576px) {
  .d-sm-fhae {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 575.98px) {
  .d-sm-fhae- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-frcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-frcc- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-frcb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-frcb- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-frcs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-frcs- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-frce {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-frce- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-frsc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-frsc- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-frsb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-frsb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-frss {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-frss- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-frse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-frse- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-frec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-frec- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-freb {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-freb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fres {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fres- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-free {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-free- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-frbc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-frbc- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-frbb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-frbb- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-frbs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-frbs- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-frbe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-frbe- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-frac {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-frac- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-frab {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-frab- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fras {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fras- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-frae {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-frae- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fccc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fccc- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fccb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fccb- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fccs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fccs- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fcce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcce- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fcsc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcsc- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fcsb {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcsb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fcss {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcss- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fcse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcse- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fcec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcec- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fceb {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fceb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fces {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fces- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fcee {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcee- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fcbc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcbc- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fcbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcbb- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fcbs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcbs- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fcbe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcbe- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fcac {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcac- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fcab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcab- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fcas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcas- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fcae {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fcae- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-flcc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-flcc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-flcb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-flcb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-flcs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-flcs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-flce {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-flce- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-flsc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-flsc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-flsb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-flsb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-flss {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-flss- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-flse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-flse- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-flec {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-flec- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fleb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fleb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fles- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-flee {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-flee- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-flbc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-flbc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-flbb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-flbb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-flbs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-flbs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-flbe {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-flbe- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-flac {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-flac- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-flab {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-flab- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-flas {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-flas- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-flae {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-flae- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fhcc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhcc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fhcb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhcb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fhcs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhcs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fhce {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhce- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fhsc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhsc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fhsb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhsb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fhss {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhss- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fhse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhse- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fhec {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhec- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fheb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fheb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fhes {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhes- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fhee {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhee- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fhbc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhbc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fhbb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhbb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fhbs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhbs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fhbe {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhbe- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .d-md-fhac {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhac- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .d-md-fhab {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhab- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .d-md-fhas {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhas- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .d-md-fhae {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 767.98px) {
  .d-md-fhae- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-frcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frcc- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-frcb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frcb- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-frcs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frcs- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-frce {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frce- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-frsc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frsc- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-frsb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frsb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-frss {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frss- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-frse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frse- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-frec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frec- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-freb {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-freb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fres {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fres- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-free {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-free- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-frbc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frbc- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-frbb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frbb- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-frbs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frbs- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-frbe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frbe- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-frac {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frac- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-frab {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frab- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fras {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fras- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-frae {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-frae- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fccc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fccc- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fccb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fccb- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fccs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fccs- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fcce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcce- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fcsc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcsc- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fcsb {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcsb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fcss {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcss- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fcse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcse- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fcec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcec- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fceb {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fceb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fces {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fces- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fcee {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcee- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fcbc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcbc- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fcbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcbb- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fcbs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcbs- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fcbe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcbe- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fcac {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcac- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fcab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcab- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fcas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcas- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fcae {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fcae- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-flcc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flcc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-flcb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flcb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-flcs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flcs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-flce {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flce- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-flsc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flsc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-flsb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flsb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-flss {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flss- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-flse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flse- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-flec {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flec- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fleb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fleb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fles- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-flee {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flee- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-flbc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flbc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-flbb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flbb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-flbs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flbs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-flbe {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flbe- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-flac {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flac- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-flab {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flab- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-flas {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flas- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-flae {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-flae- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fhcc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhcc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fhcb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhcb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fhcs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhcs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fhce {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhce- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fhsc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhsc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fhsb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhsb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fhss {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhss- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fhse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhse- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fhec {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhec- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fheb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fheb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fhes {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhes- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fhee {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhee- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fhbc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhbc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fhbb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhbb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fhbs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhbs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fhbe {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhbe- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .d-lg-fhac {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhac- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .d-lg-fhab {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhab- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 992px) {
  .d-lg-fhas {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhas- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .d-lg-fhae {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 991.98px) {
  .d-lg-fhae- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-frcc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frcc- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-frcb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frcb- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-frcs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frcs- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-frce {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frce- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-frsc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frsc- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-frsb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frsb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-frss {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frss- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-frse {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frse- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-frec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frec- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-freb {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-freb- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fres {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fres- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-free {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-free- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-frbc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frbc- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-frbb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frbb- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-frbs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frbs- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-frbe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frbe- {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-frac {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frac- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-frab {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frab- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fras {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fras- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-frae {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-frae- {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fccc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fccc- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fccb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fccb- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fccs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fccs- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcce- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcsc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcsc- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcsb {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcsb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcss {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcss- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcse- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcec {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcec- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fceb {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fceb- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fces {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fces- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcee {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcee- {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcbc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcbc- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcbb- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcbs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcbs- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcbe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcbe- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcac {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcac- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcab- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcas- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fcae {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fcae- {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-flcc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flcc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-flcb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flcb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-flcs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flcs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-flce {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flce- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-flsc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flsc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-flsb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flsb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-flss {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flss- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-flse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flse- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-flec {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flec- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fleb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fleb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fles- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-flee {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flee- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-flbc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flbc- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-flbb {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flbb- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-flbs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flbs- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-flbe {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flbe- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-flac {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flac- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-flab {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flab- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-flas {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flas- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-flae {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-flae- {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhcc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhcc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhcb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhcb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhcs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhcs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhce {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhce- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhsc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhsc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhsb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhsb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhss {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhss- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhse- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhec {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhec- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fheb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fheb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhes {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhes- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhee {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhee- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhbc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhbc- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhbb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhbb- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhbs {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhbs- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhbe {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhbe- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhac {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhac- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhab {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhab- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: baseline;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhas {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhas- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .d-xl-fhae {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

@media (max-width: 1199.98px) {
  .d-xl-fhae- {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-end;
  }
}

.w-0 {
  flex: 0 0 0%;
  width: 100%;
  max-width: 0%;
}

.w-5 {
  flex: 0 0 5%;
  width: 100%;
  max-width: 5%;
}

.w-10 {
  flex: 0 0 10%;
  width: 100%;
  max-width: 10%;
}

.w-15 {
  flex: 0 0 15%;
  width: 100%;
  max-width: 15%;
}

.w-20 {
  flex: 0 0 20%;
  width: 100%;
  max-width: 20%;
}

.w-25 {
  flex: 0 0 25%;
  width: 100%;
  max-width: 25%;
}

.w-30 {
  flex: 0 0 30%;
  width: 100%;
  max-width: 30%;
}

.w-35 {
  flex: 0 0 35%;
  width: 100%;
  max-width: 35%;
}

.w-40 {
  flex: 0 0 40%;
  width: 100%;
  max-width: 40%;
}

.w-45 {
  flex: 0 0 45%;
  width: 100%;
  max-width: 45%;
}

.w-50 {
  flex: 0 0 50%;
  width: 100%;
  max-width: 50%;
}

.w-55 {
  flex: 0 0 55%;
  width: 100%;
  max-width: 55%;
}

.w-60 {
  flex: 0 0 60%;
  width: 100%;
  max-width: 60%;
}

.w-65 {
  flex: 0 0 65%;
  width: 100%;
  max-width: 65%;
}

.w-70 {
  flex: 0 0 70%;
  width: 100%;
  max-width: 70%;
}

.w-75 {
  flex: 0 0 75%;
  width: 100%;
  max-width: 75%;
}

.w-80 {
  flex: 0 0 80%;
  width: 100%;
  max-width: 80%;
}

.w-85 {
  flex: 0 0 85%;
  width: 100%;
  max-width: 85%;
}

.w-90 {
  flex: 0 0 90%;
  width: 100%;
  max-width: 90%;
}

.w-95 {
  flex: 0 0 95%;
  width: 100%;
  max-width: 95%;
}

.w-100 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

@media (max-width: -0.02px) {
  .w-xs-0- {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (min-width: 0px) {
  .w-xs-0 {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-5- {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (min-width: 0px) {
  .w-xs-5 {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-10- {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (min-width: 0px) {
  .w-xs-10 {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-15- {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (min-width: 0px) {
  .w-xs-15 {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-20- {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (min-width: 0px) {
  .w-xs-20 {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-25- {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (min-width: 0px) {
  .w-xs-25 {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-30- {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (min-width: 0px) {
  .w-xs-30 {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-35- {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (min-width: 0px) {
  .w-xs-35 {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-40- {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (min-width: 0px) {
  .w-xs-40 {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-45- {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (min-width: 0px) {
  .w-xs-45 {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-50- {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (min-width: 0px) {
  .w-xs-50 {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-55- {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (min-width: 0px) {
  .w-xs-55 {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-60- {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (min-width: 0px) {
  .w-xs-60 {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-65- {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (min-width: 0px) {
  .w-xs-65 {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-70- {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (min-width: 0px) {
  .w-xs-70 {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-75- {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (min-width: 0px) {
  .w-xs-75 {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-80- {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (min-width: 0px) {
  .w-xs-80 {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-85- {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (min-width: 0px) {
  .w-xs-85 {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-90- {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (min-width: 0px) {
  .w-xs-90 {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-95- {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (min-width: 0px) {
  .w-xs-95 {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (max-width: -0.02px) {
  .w-xs-100- {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 0px) {
  .w-xs-100 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-0- {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (min-width: 576px) {
  .w-sm-0 {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-5- {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (min-width: 576px) {
  .w-sm-5 {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-10- {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (min-width: 576px) {
  .w-sm-10 {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-15- {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (min-width: 576px) {
  .w-sm-15 {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-20- {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (min-width: 576px) {
  .w-sm-20 {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-25- {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (min-width: 576px) {
  .w-sm-25 {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-30- {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (min-width: 576px) {
  .w-sm-30 {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-35- {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (min-width: 576px) {
  .w-sm-35 {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-40- {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (min-width: 576px) {
  .w-sm-40 {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-45- {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (min-width: 576px) {
  .w-sm-45 {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-50- {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .w-sm-50 {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-55- {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (min-width: 576px) {
  .w-sm-55 {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-60- {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (min-width: 576px) {
  .w-sm-60 {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-65- {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (min-width: 576px) {
  .w-sm-65 {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-70- {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (min-width: 576px) {
  .w-sm-70 {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-75- {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (min-width: 576px) {
  .w-sm-75 {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-80- {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (min-width: 576px) {
  .w-sm-80 {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-85- {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (min-width: 576px) {
  .w-sm-85 {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-90- {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (min-width: 576px) {
  .w-sm-90 {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-95- {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (min-width: 576px) {
  .w-sm-95 {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (max-width: 575.98px) {
  .w-sm-100- {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .w-sm-100 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .w-md-0- {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (min-width: 768px) {
  .w-md-0 {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (max-width: 767.98px) {
  .w-md-5- {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (min-width: 768px) {
  .w-md-5 {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (max-width: 767.98px) {
  .w-md-10- {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (min-width: 768px) {
  .w-md-10 {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (max-width: 767.98px) {
  .w-md-15- {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (min-width: 768px) {
  .w-md-15 {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (max-width: 767.98px) {
  .w-md-20- {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (min-width: 768px) {
  .w-md-20 {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (max-width: 767.98px) {
  .w-md-25- {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (max-width: 767.98px) {
  .w-md-30- {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (min-width: 768px) {
  .w-md-30 {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (max-width: 767.98px) {
  .w-md-35- {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (min-width: 768px) {
  .w-md-35 {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (max-width: 767.98px) {
  .w-md-40- {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (min-width: 768px) {
  .w-md-40 {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (max-width: 767.98px) {
  .w-md-45- {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (min-width: 768px) {
  .w-md-45 {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (max-width: 767.98px) {
  .w-md-50- {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .w-md-55- {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (min-width: 768px) {
  .w-md-55 {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (max-width: 767.98px) {
  .w-md-60- {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (min-width: 768px) {
  .w-md-60 {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 767.98px) {
  .w-md-65- {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (min-width: 768px) {
  .w-md-65 {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (max-width: 767.98px) {
  .w-md-70- {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (min-width: 768px) {
  .w-md-70 {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (max-width: 767.98px) {
  .w-md-75- {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (min-width: 768px) {
  .w-md-75 {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (max-width: 767.98px) {
  .w-md-80- {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .w-md-80 {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (max-width: 767.98px) {
  .w-md-85- {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (min-width: 768px) {
  .w-md-85 {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (max-width: 767.98px) {
  .w-md-90- {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (min-width: 768px) {
  .w-md-90 {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: 767.98px) {
  .w-md-95- {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (min-width: 768px) {
  .w-md-95 {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (max-width: 767.98px) {
  .w-md-100- {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-0- {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (min-width: 992px) {
  .w-lg-0 {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-5- {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (min-width: 992px) {
  .w-lg-5 {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-10- {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (min-width: 992px) {
  .w-lg-10 {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-15- {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (min-width: 992px) {
  .w-lg-15 {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-20- {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .w-lg-20 {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-25- {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-30- {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (min-width: 992px) {
  .w-lg-30 {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-35- {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (min-width: 992px) {
  .w-lg-35 {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-40- {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (min-width: 992px) {
  .w-lg-40 {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-45- {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (min-width: 992px) {
  .w-lg-45 {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-50- {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .w-lg-50 {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-55- {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (min-width: 992px) {
  .w-lg-55 {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-60- {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (min-width: 992px) {
  .w-lg-60 {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-65- {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (min-width: 992px) {
  .w-lg-65 {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-70- {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (min-width: 992px) {
  .w-lg-70 {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-75- {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-80- {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .w-lg-80 {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-85- {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (min-width: 992px) {
  .w-lg-85 {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-90- {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (min-width: 992px) {
  .w-lg-90 {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-95- {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (min-width: 992px) {
  .w-lg-95 {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-100- {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-0- {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (min-width: 1200px) {
  .w-xl-0 {
    flex: 0 0 0%;
    width: 100%;
    max-width: 0%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-5- {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (min-width: 1200px) {
  .w-xl-5 {
    flex: 0 0 5%;
    width: 100%;
    max-width: 5%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-10- {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (min-width: 1200px) {
  .w-xl-10 {
    flex: 0 0 10%;
    width: 100%;
    max-width: 10%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-15- {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (min-width: 1200px) {
  .w-xl-15 {
    flex: 0 0 15%;
    width: 100%;
    max-width: 15%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-20- {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .w-xl-20 {
    flex: 0 0 20%;
    width: 100%;
    max-width: 20%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-25- {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    flex: 0 0 25%;
    width: 100%;
    max-width: 25%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-30- {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (min-width: 1200px) {
  .w-xl-30 {
    flex: 0 0 30%;
    width: 100%;
    max-width: 30%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-35- {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (min-width: 1200px) {
  .w-xl-35 {
    flex: 0 0 35%;
    width: 100%;
    max-width: 35%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-40- {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (min-width: 1200px) {
  .w-xl-40 {
    flex: 0 0 40%;
    width: 100%;
    max-width: 40%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-45- {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (min-width: 1200px) {
  .w-xl-45 {
    flex: 0 0 45%;
    width: 100%;
    max-width: 45%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-50- {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-55- {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (min-width: 1200px) {
  .w-xl-55 {
    flex: 0 0 55%;
    width: 100%;
    max-width: 55%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-60- {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (min-width: 1200px) {
  .w-xl-60 {
    flex: 0 0 60%;
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-65- {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (min-width: 1200px) {
  .w-xl-65 {
    flex: 0 0 65%;
    width: 100%;
    max-width: 65%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-70- {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (min-width: 1200px) {
  .w-xl-70 {
    flex: 0 0 70%;
    width: 100%;
    max-width: 70%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-75- {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (min-width: 1200px) {
  .w-xl-75 {
    flex: 0 0 75%;
    width: 100%;
    max-width: 75%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-80- {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (min-width: 1200px) {
  .w-xl-80 {
    flex: 0 0 80%;
    width: 100%;
    max-width: 80%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-85- {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (min-width: 1200px) {
  .w-xl-85 {
    flex: 0 0 85%;
    width: 100%;
    max-width: 85%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-90- {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .w-xl-90 {
    flex: 0 0 90%;
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-95- {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (min-width: 1200px) {
  .w-xl-95 {
    flex: 0 0 95%;
    width: 100%;
    max-width: 95%;
  }
}

@media (max-width: 1199.98px) {
  .w-xl-100- {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

/* расширение контейнера бутстрапа */
@media (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}

.main-container {
  max-width: 1380px;
}

@media (max-width: 380px) {
  .container-fluid {
    padding-left: 8px;
    padding-right: 8px;
  }
}

body {
  color: #000;
  background-color: #ffefef;
  font-family: -apple-system, BlinkMacSystemFont, "Comfortaa", "Roboto", "Helvetica Neue", Arial, cursive, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
  color: #996600;
}

a:hover {
  color: #66cccc;
  background-color: #339966;
}

.socials a:hover {
  background-color: unset;
}

a.no-hover {
  color: unset;
}

a.no-hover:hover {
  color: unset;
  background-color: unset;
  text-decoration: unset;
}

.card-img-top {
  width: 4rem;
  height: 4rem;
}

.card-footer {
  background-color: transparent;
}

/*количество карточек на столе*/
.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck .card {
    margin-top: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card-deck .card {
    min-width: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }
}

@media (min-width: 768px) {
  .card-deck .card {
    min-width: calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@media (min-width: 1200px) {
  .card-deck .card {
    min-width: calc(33.3333% - 30px);
    max-width: calc(33.3333% - 30px);
  }
}

.form-control, .btn {
  border-radius: 0;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.5;
}

.form-control:focus {
  border-color: #009966;
  border-inline-color: #009966;
}

.select2-container--bootstrap4 {
  background-color: #fff;
}

.select2-container--bootstrap4 .select2-selection {
  border-radius: 0;
}

.dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  border: none;
  padding: 0;
  overflow: hidden;
  transform-origin: top left;
  transform: scale(0);
  transition: .4s ease all;
  min-width: 0;
  text-transform: none;
}

.dropdown-item {
  white-space: unset;
}

@media (min-width: 768px) {
  .dropdown-menu {
    min-width: 10rem;
    max-width: 50vw;
    width: max-content;
    margin-top: 0;
  }
  .dropdown-item {
    white-space: nowrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .dropdown-md-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-md-toggle:empty::after {
    margin-left: 0;
  }
}

.dropdown-menu.show {
  display: block;
  visibility: visible;
  opacity: 1;
  max-height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transform: scale(1);
  transition: .4s ease all;
}

@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
    max-height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transform: scale(1);
    transition: .4s ease all;
  }
}

/* скрытая полоса прокрутки */
.hidden-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+*/
}

.hidden-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* стили хидера */
#header a.nav-link {
  margin-left: 2px;
  margin-right: 2px;
}

@media (max-width: 767.98px) {
  #header a.nav-link {
    padding-left: 0.5rem;
  }
}

/* стили футера */
#footer {
  font-size: 0.8rem;
}

#footer .btn {
  box-shadow: none;
}

@media (min-width: 768px) {
  #footer .collapse:not(.show) {
    display: block;
  }
}
