@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/caret";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

@import "colors";
@import "fonts";
@import "utils";

/* расширение контейнера бутстрапа */
@media (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}
.main-container {
  max-width: 1380px;
}
@media (max-width: 380px) {
  .container-fluid {
    padding-left: 8px;
    padding-right: 8px;
  }
}

body {
  color: $color-text-main;
  background-color: $color-main-bg;
  font-family:
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    // "Segoe UI", // используем на Windows Roboto
    "Comfortaa",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Arial, cursive, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
a {
  color: $color-link;
}
a:hover {
  color: $color-link-hover-text;
  background-color: $color-link-hover-bg;
}
.socials {
  a:hover {
    background-color: unset;
  }
}
a.no-hover {
  color: unset;
  &:hover {
    color: unset;
    background-color: unset;
    text-decoration: unset;
  }
}

.card-img-top {
  width: 4rem;
  height: 4rem;
}
.card-footer {
  background-color: transparent;
}
/*количество карточек на столе*/
.card-deck .card {
  margin-bottom: 15px;

  @include media-breakpoint-up(sm) {
    margin-top: 15px;
  }
  @include media-breakpoint-only(sm) {
    min-width: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }
  @include media-breakpoint-between(md, xl) {
    min-width: calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
  @include media-breakpoint-up(xl) {
    min-width: calc(33.3333% - 30px);
    max-width: calc(33.3333% - 30px);
  }
}
.form-control, .btn {
  border-radius: 0;
}
.form-control::placeholder {
  color: $gray-600;
  opacity: 0.5;
}
.form-control:focus {
  border-color: $color-primary-0;
  border-inline-color: $color-primary-0;
}
.select2-container--bootstrap4 {
  background-color: $white;
}
.select2-container--bootstrap4  .select2-selection {
  border-radius: 0;
}

// Плавное открытие меню
.dropdown-menu {
  //Всегда надо block, иначе не работают анимации
  display: block;
  // Поскольку всегда display: block - приходится скрывать блок, используя 3 свойства одновременно:
  // visibility, opacity, max-height
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  // Приходится убирать padding и скрывать границу, ибо они дают высоту, на которую не действует max-height
  border: none;
  padding: 0;
  // Из-за использования max-height, приодится скрывать "лишнее" за пределами блока
  overflow: hidden;
  // С transform: scale показ менюшки выглядит более привлекательным, хотя работает и без него
  transform-origin: top left;
  transform: scale(0);
  // Собственно анимация
  transition: .4s ease all;
  // На мобильных устройствах min-width: 10rem (из стандартного .dropdown-menu) - это слишком широко, убираем
  min-width: 0;
  // Отключаем принудительный uppercase всего меню
  text-transform: none;
}
// Из-за нового min-width: 0 надо включить обратно перенос по словам в элементах меню
.dropdown-item {
  white-space: unset;
}
// А на больших разрешениях возвращаем назад min-width и white-space
@include media-breakpoint-up(md) {
  .dropdown-menu {
    min-width: 10rem;
    max-width: 50vw;
    width: max-content;
    margin-top: 0;
  }
  .dropdown-item {
    white-space: nowrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .dropdown-md-toggle {
    @include caret();
  }
}

@mixin dropdown-menu-show() {
  display: block;
  visibility: visible;
  opacity: 1;
  // Максимальное значение max-height надо подбирать опытным путем по фактической менюшке.
  // У нас оно сейчас порядка 450px на самых маленких разрешениях. Ставим 500px с запасом
  max-height: 500px;
  border: 1px solid rgba(0,0,0,.15);

  transform: scale(1);
  transition: .4s ease all;
}
.dropdown-menu.show {
  @include dropdown-menu-show();
}
@include media-breakpoint-up(md) {
  .dropdown:hover .dropdown-menu {
    @include dropdown-menu-show();
  }
}

/* скрытая полоса прокрутки */
.hidden-scroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+*/
}
.hidden-scroll::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

/* стили хидера */
#header {
  a.nav-link {
    margin-left: 2px;
    margin-right: 2px;
    @include media-breakpoint-down(sm) {
      padding-left: 0.5rem;
    }
  }
}

/* стили футера */
#footer {
  font-size: 0.8rem;
  .btn {
    box-shadow: none;
  }
  @include media-breakpoint-up(md) {
    .collapse:not(.show) {
      display: block;
    }
  }
}
