@font-face {
  font-family: 'Comfortaa';
  font-display: swap;
  src: url('/media/fonts/Comfortaa-Bold.eot');
  src: local('Comfortaa Bold'), local('Comfortaa-Bold'),
  url('/media/fonts/Comfortaa-Bold.eot?#iefix') format('embedded-opentype'),
  url('/media/fonts/Comfortaa-Bold.woff2') format('woff2'),
  url('/media/fonts/Comfortaa-Bold.woff') format('woff'),
  url('/media/fonts/Comfortaa-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  font-display: swap;
  src: url('/media/fonts/Comfortaa-Regular.eot');
  src: local('Comfortaa Regular'), local('Comfortaa-Regular'),
  url('/media/fonts/Comfortaa-Regular.eot?#iefix') format('embedded-opentype'),
  url('/media/fonts/Comfortaa-Regular.woff2') format('woff2'),
  url('/media/fonts/Comfortaa-Regular.woff') format('woff'),
  url('/media/fonts/Comfortaa-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font-normal {
  font-size: 1.2rem;
}
.font-small {
  font-size: 1rem;
}
.font-little {
  font-size: 0.8rem;
}

@for $value from 0 through 40 {
  .font-#{$value * 5} {
    font-size: ($value * 5 * 1%);
  }
}

/* правила для поворота стрелок в аккордеонах */
.collapse-icon::before {
  transition: transform ease 200ms;
  display: inline-block;
}
.collapsed .collapse-icon::before,
.collapsed.collapse-icon::before {
  transform: rotate(180deg);
}
