$direction: (
  r: row,
  c: column,
  l: row-reverse,
  h: column-reverse,
);
$justify: (
  c: center,
  s: flex-start,
  e: flex-end,
  b: space-between,
  a: space-around,
);
$align: (
  c: center,
  b: baseline,
  s: flex-start,
  e: flex-end,
);
$breakpoint: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@each $bk, $bv in $breakpoint {
  @media (max-width: ($bv - 0.02px)) {
    .d-#{$bk}-none- {
      display: none;
    }
  }
}

@each $dk, $dv in $direction {
  @each $jk, $jv in $justify {
    @each $ak, $av in $align {
      .d-f#{$dk}#{$jk}#{$ak} {
        display: flex;
        flex-direction: $dv;
        justify-content: $jv;
        align-items: $av;
      }
    }
  }
}
@each $bk, $bv in $breakpoint {
  @each $dk, $dv in $direction {
    @each $jk, $jv in $justify {
      @each $ak, $av in $align {
        @media(min-width: $bv) {
          .d-#{$bk}-f#{$dk}#{$jk}#{$ak} {
          display: flex;
            flex-direction: $dv;
            justify-content: $jv;
            align-items: $av;
        }
        }
        @media(max-width: ($bv - 0.02px)) {
          .d-#{$bk}-f#{$dk}#{$jk}#{$ak}- {
            display: flex;
            flex-direction: $dv;
            justify-content: $jv;
            align-items: $av;
          }
        }
      }
    }
  }
}

@for $value from 0 through 20 {
  .w-#{$value * 5} {
    flex: 0 0 ($value * 5 * 1%);
    width: 100%;
    max-width: ($value * 5 * 1%);
  }
}
@each $bk, $bv in $breakpoint {
    @for $value from 0 through 20 {
      @media(max-width: ($bv - 0.02px)) {
        .w-#{$bk}-#{$value * 5}- {
          flex: 0 0 ($value * 5 * 1%);
          width: 100%;
          max-width: ($value * 5 * 1%);
        }
      }
    @media(min-width: $bv) {
      .w-#{$bk}-#{$value * 5} {
        flex: 0 0 ($value * 5 * 1%);
        width: 100%;
        max-width: ($value * 5 * 1%);
      }
    }
  }
}
